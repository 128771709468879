<template lang="html" src="./statisticsCard.template.vue"></template>

<style lang="scss" src="./statisticsCard.scss"></style>

<script>
import { ApiErrorParser, SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';
import Chart from 'chart.js/auto';

// const Chart = require('chart.js');

const i18nData = require('./statisticsCard.i18n');

export default {
  name: 'StatisticsCard',
  components: { SpinnerBeelse },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      archivedPartsTotals: null,
      horizontalBarChart: null,
      barData: {},
      optionsBarDat: {},
      orders: null,
      myChart: null,
      myLineChart: null,
      ctx: null,
      ctxLineChart: null,
      partsList: null,
      partsLabels: [],
      countArray: [],
      colors: [
        '250, 25, 0',
        '250, 204, 0',
        '0, 184, 6',
        '70, 0, 184',
        '245, 255, 51',
        '51, 211, 255',
      ],
      ordersDurationLabels: [],
      durationValues: [],
      backgroundsColorArray: [],
      quantityPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      spinnerIsRunning: false,
      totalAmountPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      datasetForTotalsProductedPerMonthAndPerProductionLineLabels: [],
      totalsProductedPerMonthAndPerProductionLine: [],
      totalPartsArchivedByPrintedLine: null,
      totalPartsArchivedByPrintedLineLabels: null,
    };
  },
  mounted() {
    this.importDatas();
  },
  methods: {
    async createChart() {
      this.ctx = await document.getElementById('myChart');
      this.myChart = await new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: this.totalPartsArchivedByPrintedLineLabels,
          datasets: [
            {
              label: `${this.$t('Printed')}`,
              data: this.totalPartsArchivedByPrintedLine,
              backgroundColor: 'rgba(252, 103, 0, 0.2)',
              borderColor: this.backgroundsColorArray,
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(252, 103, 0, 1)',
              hoverBorderColor: 'rgba(252, 103, 0, 1)',
            },
          ],
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.$t('partsNumberByPrintedLines'),
          },
        },
      });
      this.spinnerIsRunning = false;
      const datasetForTotalsProductedPerMonthAndPerProductionLine = [];
      this.totalsProductedPerMonthAndPerProductionLine.forEach(
        (productionLine, index) => {
          datasetForTotalsProductedPerMonthAndPerProductionLine.push({
            label: this.totalPartsArchivedByPrintedLineLabels[index],
            data: productionLine,
            yAxisID: `${this.$t('Parts')}`,
            backgroundColor: `rgba(${this.colors[index]}, 0.1)`,
            borderColor: `rgba(${this.colors[index]}, 1)`,
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(252, 103, 0, 1)',
            hoverBorderColor: 'rgba(252, 103, 0, 1)',
          });
        }
      );
      this.ctxLineChart = await document.getElementById('myLineChart');
      this.myLineChart = await new Chart(this.ctxLineChart, {
        type: 'line',
        data: {
          labels: this
            .datasetForTotalsProductedPerMonthAndPerProductionLineLabels,
          datasets: datasetForTotalsProductedPerMonthAndPerProductionLine,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                type: 'time',
                distribution: 'series',
                ticks: {
                  source: 'data',
                },
                time: {
                  unit: 'day',
                  round: 'day',
                },
              },
            ],
            yAxes: [
              {
                id: `${this.$t('Parts')}`,
                type: 'linear',
                position: 'left',
                ticks: {
                  stepSize: 1,
                },
              },
            ],
          },
          title: {
            display: true,
            text: this.$t('numberOfJobsPerProductionLine'),
          },
          legend: {
            display: true,
          },
        },
      });
    },
    groupBy(tableauObjets, propriete) {
      return tableauObjets.reduce(function(acc, obj) {
        const cle = obj[propriete];
        if (!acc[cle]) {
          acc[cle] = [];
        }
        acc[cle].push(obj);
        return acc;
      }, {});
    },
    formatTotalPrintedParts() {
      const result = Array.from(
        this.totalPartsArchivedByPrintedLine
          .reduce((a, b) => a + b, 0)
          .toString()
      );
      if (result.length < 8) {
        for (let index = 0; index < 10 - result.length; index++) {
          result.unshift('0');
        }
      }
      return result;
    },
    importDatas() {
      this.spinnerIsRunning = true;
      this.totalParts = 0;
      this.totalPartsArchivedByPrintedLine = [];
      this.totalPartsArchivedByPrintedLineLabels = [];
      this.jobsNestingSizePercent = [];
      this.$apiInstance
        .getSupplierPrinters(this.$route.params.supplierUUID)
        .then(
          (printers) => {
            printers.forEach((printer) => {
              const totalDataCurrentPrint = [];
              this.$apiInstance
                .getSupplierPrinterLinesArchivesMetadata(
                  this.$route.params.supplierUUID,
                  printer.brand,
                  printer.model
                )
                .then(
                  (result) => {
                    let jobTotal = 0;
                    if (result.jobs && result.jobs.length > 0) {
                      result.jobs.forEach((job) => {
                        totalDataCurrentPrint.push({
                          date: this.$d(new Date(job.dateSentToProduction), 'short'),
                          type: 'dateSentToProduction',
                        });
                        if (job.dateSentToProduction !== job.last_modified) {
                          totalDataCurrentPrint.push({
                            date: this.$d(new Date(job.last_modified), 'short'),
                            type: 'lastModified',
                          });
                        }
                        job.parts.forEach((part) => {
                          jobTotal += part.quantity;
                        });
                      });
                    }
                    this.totalPartsArchivedByPrintedLine.push(jobTotal);
                    this.totalPartsArchivedByPrintedLineLabels.push(
                      printer.brand + ' ' + printer.model
                    );
                  },
                  (error) => {
                    /**
                     * ERROR GET ALL QUOTES
                     */
                    ApiErrorParser.parse(error);
                  }
                );
              this.totalsProductedPerMonthAndPerProductionLine.push(
                totalDataCurrentPrint
              );
            });
            setTimeout(() => {
              if (
                this.totalPartsArchivedByPrintedLine &&
                this.totalPartsArchivedByPrintedLine.length > 0
              ) {
                this.totalsProductedPerMonthAndPerProductionLine.forEach(
                  (unsortDatesForOnePrintedLine) => {
                    unsortDatesForOnePrintedLine = unsortDatesForOnePrintedLine.sort(
                      function(a, b) {
                        return new Date(a.date) - new Date(b.date);
                      }
                    );
                    let numberOfPrintersOccupiedPerTime = 0;
                    unsortDatesForOnePrintedLine.forEach((data) => {
                      data.x = data.date;
                      data.y =
                        data.type === 'dateSentToProduction'
                          ? (numberOfPrintersOccupiedPerTime += 1)
                          : (numberOfPrintersOccupiedPerTime -= 1);
                      this.datasetForTotalsProductedPerMonthAndPerProductionLineLabels.push(
                        data.date
                      );

                      delete data.date;
                      delete data.type;
                    });
                    unsortDatesForOnePrintedLine.unshift({ x: 0, y: 0 });

                    const lastValue =
                      unsortDatesForOnePrintedLine[
                        unsortDatesForOnePrintedLine.length - 1
                      ].y;
                    unsortDatesForOnePrintedLine.push({
                      x: new Date(),
                      y: lastValue,
                    });
                  }
                );
                this.createChart();
              } else {
                this.spinnerIsRunning = false;
              }
            }, 2000);
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    dayDiff(d1, d2) {
      d1 = d1.getTime() / 86400000;
      d2 = d2.getTime() / 86400000;
      return (d2 - d1).toFixed(0);
    },
    hex(c) {
      const s = '0123456789abcdef';
      let i = parseInt(c);
      if (i === 0 || isNaN(c)) return '00';
      i = Math.round(Math.min(Math.max(0, i), 255));
      return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
    },

    /* Convert an RGB triplet to a hex string */
    convertToHex(rgb) {
      return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
    },

    /* Remove '#' in color hex string */
    trim(s) {
      return s.charAt(0) === '#' ? s.substring(1, 7) : s;
    },

    /* Convert a hex string to an RGB triplet */
    convertToRGB(hex) {
      const color = [];
      color[0] = parseInt(this.trim(hex).substring(0, 2), 16);
      color[1] = parseInt(this.trim(hex).substring(2, 4), 16);
      color[2] = parseInt(this.trim(hex).substring(4, 6), 16);
      return color;
    },
    generateColor(colorStart, colorEnd, colorCount) {
      // The beginning of your gradient
      const start = this.convertToRGB(colorStart);

      // The end of your gradient
      const end = this.convertToRGB(colorEnd);

      // The number of colors to compute
      const len = colorCount;

      //Alpha blending amount
      let alpha = 0.0;

      const saida = [];

      for (let i = 0; i < len; i++) {
        const c = [];
        alpha += 1.0 / len;

        c[0] = start[0] * alpha + (1 - alpha) * end[0];
        c[1] = start[1] * alpha + (1 - alpha) * end[1];
        c[2] = start[2] * alpha + (1 - alpha) * end[2];

        saida.push(`rgba(${c[0]}, ${c[1]}, ${c[2]}, 1)`);
      }

      return saida;
    },
  },
};
</script>
