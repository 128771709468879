var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-dashboard"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Dashboard')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loadingActionsToDoAndOrdersStatus,
      "disabled": _vm.loadingActionsToDoAndOrdersStatus,
      "color": "primary",
      "title": _vm.$t('refreshPage')
    },
    on: {
      "click": _vm.resetDataAndReload
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-1"
  }), _c('v-container', {
    staticClass: "px-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "white d-inline rounded-tl-l rounded-tr-xl pa-2 pt-3 elevation-1"
  }, [_c('span', {
    staticClass: "mx-3 tabTitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("BCMAtSupplier", {
    supplierName: _vm.supplierName
  })) + " ")])]), _c('v-card', {
    staticClass: "rounded-tl-0 mt-4"
  }, [_c('v-row', {
    staticClass: "d-block rowResume py-1",
    attrs: {
      "align": "stretch"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-card-title', {
    staticClass: "py-0"
  }, [_c('v-img', {
    attrs: {
      "src": require("../../../public/img/icons/android-chrome-512x512.png"),
      "alt": "Logo CMT",
      "max-width": "50px",
      "max-height": "50px"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "verticalDivider orange my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "verticalDivider orange my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../../public/img/icons/manufacturingIcon.svg"),
      "alt": "manufacturing icon 3D print",
      "max-width": "33px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('ManufacturingParts')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.manufacturingCountParts) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('part(s)', _vm.manufacturingCountParts)) + " ")])], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../../public/img/icons/cubeCheckedOrangeIcon.svg"),
      "alt": "icon 3D print",
      "max-width": "36px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('PrintedParts')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.totalCountParts) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('part(s)', _vm.totalCountParts)) + " ")])], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconDelivered.png"),
      "alt": "icon 3D print",
      "max-width": "30px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('OrdersDelivered')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.ordersDelivered) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('order(s)', _vm.ordersDelivered)) + " ")])], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconDelivery.png"),
      "alt": "icon 3D print",
      "max-width": "30px"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('OrdersInDelivery')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.ordersInDelivery) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('order(s)', _vm.ordersInDelivery)) + " ")])], 1)], 1)], 1), !_vm.loadingActionsToDoAndOrdersStatus && _vm.ordersScheduled && _vm.homologationList && _vm.ordersAwaitingInvoice ? _c('v-row', {
    key: _vm.ordersScheduled.length + _vm.homologationList.length + _vm.ordersAwaitingInvoice.length,
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', {
    staticClass: "mt-2 actionsToDoCol",
    attrs: {
      "cols": "7"
    }
  }, [_c('ActionsToDo', {
    attrs: {
      "orders-scheduled": _vm.ordersScheduled,
      "homologations": _vm.homologationList,
      "orders-awaiting-invoice": _vm.ordersAwaitingInvoice
    }
  })], 1), _c('v-col', {
    staticClass: "mt-2 ordersTrackingCol"
  }, [_c('FollowUpOfOrdersInProgress', {
    attrs: {
      "orders": _vm.currentOrders,
      "maximum": 5
    }
  })], 1)], 1) : _c('v-row', {
    staticClass: "my-2",
    staticStyle: {
      "height": "300px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-card', {
    staticClass: "col-12 d-flex justify-center align-center",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('SpinnerBeelse', {
    attrs: {
      "color": "orange",
      "multiple": "",
      "logo": "SimpleB",
      "display-logo": false
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    staticClass: "d-block rowResume py-1",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('ProductionModule')))]), _vm.partsAwaitingPlacement && _vm.partsAwaitingPlacement > 0 ? _c('v-btn', {
    staticClass: "font-weight-bold white--text mr-3",
    attrs: {
      "color": "orange",
      "to": {
        name: 'Production',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID)
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('PartsAwaitingPlacement', {
    number: _vm.partsAwaitingPlacement
  })) + " ")], 1) : _c('v-btn', {
    staticClass: "font-weight-bold white--text mr-3",
    attrs: {
      "color": "orange",
      "to": {
        name: 'Production',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID)
        }
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-eye ")])], 1)], 1), _c('v-row', [_c('StatisticsCard')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }