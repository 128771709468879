var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-actionsToDo"
  }, [_c('v-card', {
    staticClass: "cardActionsToDo pb-4"
  }, [_c('v-card-title', {
    key: 'actionsToDo' + _vm.actionsToDo
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-calendar-day ")]), _vm._v(" " + _vm._s(_vm.$t('ActionsToDo')) + " (" + _vm._s(_vm.actionsToDo) + ") ")], 1), _vm.actionsToDo !== 0 ? _c('v-row', {
    staticClass: "d-block d-lg-flex mx-1"
  }, [_vm.ordersScheduled && _vm.ordersScheduled.length > 0 ? _c('v-col', {
    staticClass: "pb-5 actionToDoCol"
  }, [_c('v-card', [_c('v-img', {
    staticClass: "pinIcon",
    attrs: {
      "src": require("../../../public/img/icons/pin_pressed.png"),
      "alt": "icon pin",
      "width": "40",
      "height": "auto"
    }
  }), _c('v-card-title', {
    staticClass: "ml-8"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('OrdersAwaitingProduction', {
        numberOfOrders: _vm.ordersScheduled.length
      }))
    }
  })]), _c('v-card-text', [_vm._l(_vm.ordersScheduled, function (order, index) {
    return [_c('transition-group', {
      key: 'transition-group' + index,
      attrs: {
        "name": "list-complete-order-scheduled",
        "tag": "div"
      }
    }, [index < 2 || _vm.displayMoreOrdersScheduled ? _c('v-row', {
      key: order._id + index,
      staticClass: "action mb-2 list-complete-item-order-scheduled",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "\n                    rounded-l-xl\n                    outlined\n                    align-self-stretch\n                    d-flex\n                    align-center\n                    pl-2 pl-md-6\n                  ",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('div', {
      staticClass: "pa-1"
    }, [_c('span', {
      staticClass: "mr-2 text-subtitle-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('orderNumber', {
      docNumber: order.invoiceNumber
    })) + " "), _c('span', {
      staticClass: "text-no-wrap"
    }, [_vm._v(" (" + _vm._s(_vm.orderItemsQuantity(order)) + " " + _vm._s(_vm.$tc('part(s)', _vm.orderItemsQuantity(order))) + ") ")])])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "\n                      white--text\n                      align-self-stretch\n                      rounded-l-0 rounded-r-xl\n                    ",
      attrs: {
        "color": "orange",
        "depressed": "",
        "height": "100%",
        "large": "",
        "block": "",
        "to": {
          name: 'Order',
          params: {
            supplierUUID: "".concat(_vm.$route.params.supplierUUID),
            orderUUID: "".concat(order._id)
          }
        },
        "ripple": false
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Go!')) + " ")])], 1)], 1) : _vm._e()], 1)];
  }), _vm.ordersScheduled.length > 2 ? _c('v-row', {
    staticStyle: {
      "position": "absolute",
      "right": "50%",
      "left": "50%"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "py-4 mt-3 white text-none caption elevation-0",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.displayMoreOrdersScheduled = !_vm.displayMoreOrdersScheduled;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "duration": _vm.displayMoreOrdersScheduled ? {
        enter: 200,
        leave: 200
      } : {
        enter: 1000,
        leave: 500
      },
      "tag": "div",
      "mode": "out-in"
    }
  }, [!_vm.displayMoreOrdersScheduled ? _c('span', {
    key: "arrowIconDown",
    staticClass: "\n                    d-flex\n                    text-none text-body-2\n                    align-center\n                    orange--text\n                  "
  }, [_vm._v(" " + _vm._s(_vm.$t("Plus", {
    number: _vm.ordersScheduled.length - 2
  })) + " ")]) : _c('span', {
    key: "arrowIconUp"
  }, [_c('v-icon', {
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("fas fa-chevron-up")])], 1)])], 1)], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _vm.homologations && _vm.homologations.length > 0 ? _c('v-col', {
    staticClass: "pb-5 actionToDoCol"
  }, [_c('v-card', [_c('v-img', {
    staticStyle: {
      "position": "absolute",
      "left": "8px",
      "top": "-12px"
    },
    attrs: {
      "src": require("../../../public/img/icons/pin_pressed.png"),
      "alt": "icon pin",
      "width": "40",
      "height": "auto"
    }
  }), _c('v-card-title', {
    staticClass: "ml-8"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('PartsToValidate', {
        number: _vm.homologations.length
      }))
    }
  })]), _c('v-card-text', [_vm._l(_vm.homologations, function (part, index) {
    return [_c('transition-group', {
      key: 'transition-group' + index,
      attrs: {
        "name": "list-complete-homologations",
        "tag": "div"
      }
    }, [index < 2 || _vm.displayMoreHomologationList ? _c('v-row', {
      key: index,
      staticClass: "action mb-2 list-complete-item-homologations",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "\n                    rounded-l-xl\n                    outlined\n                    align-self-stretch\n                    d-flex\n                    align-center\n                    pl-2 pl-md-6\n                  ",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('div', {
      staticClass: "pa-1 d-flex flex-wrap text-subtitle-1 align-center"
    }, [_c('span', {
      staticClass: "mr-2 d-inline-block text-no-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$d(part.last_modified)) + " ")]), _c('span', {
      staticClass: "d-inline-block text-truncate",
      staticStyle: {
        "max-width": "150px"
      }
    }, [_vm._v(" " + _vm._s(part.name) + " ")])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "\n                      white--text\n                      align-self-stretch\n                      rounded-l-0 rounded-r-xl\n                    ",
      attrs: {
        "color": "orange",
        "depressed": "",
        "height": "100%",
        "large": "",
        "block": "",
        "to": {
          name: 'Part',
          params: {
            supplierUUID: "".concat(_vm.$route.params.supplierUUID),
            partUUID: "".concat(part._id)
          }
        },
        "ripple": false
      }
    }, [_c('v-icon', [_vm._v("fas fa-pen")])], 1)], 1)], 1) : _vm._e()], 1)];
  }), _vm.homologations.length > 2 ? _c('v-row', {
    staticStyle: {
      "position": "absolute",
      "right": "50%",
      "left": "50%"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "py-4 mt-3 white elevation-0",
    attrs: {
      "right": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.displayMoreHomologationList = !_vm.displayMoreHomologationList;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "duration": _vm.displayMoreHomologationList ? {
        enter: 200,
        leave: 200
      } : {
        enter: 1000,
        leave: 500
      },
      "tag": "div",
      "mode": "out-in"
    }
  }, [!_vm.displayMoreHomologationList ? _c('span', {
    key: "arrowIconDown",
    staticClass: "\n                    text-none text-body-2\n                    d-flex\n                    align-center\n                    orange--text\n                  "
  }, [_vm._v(" " + _vm._s(_vm.$t("Plus", {
    number: _vm.homologations.length - 2
  })) + " ")]) : _c('span', {
    key: "arrowIconUp"
  }, [_c('v-icon', {
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("fas fa-chevron-up")])], 1)])], 1)], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _vm.ordersAwaitingInvoice && _vm.ordersAwaitingInvoice.length > 0 ? _c('v-col', {
    staticClass: "pb-5 actionToDoCol"
  }, [_c('v-card', [_c('v-img', {
    staticStyle: {
      "position": "absolute",
      "left": "8px",
      "top": "-12px"
    },
    attrs: {
      "src": require("../../../public/img/icons/pin_pressed.png"),
      "alt": "icon pin",
      "width": "40",
      "height": "auto"
    }
  }), _c('v-card-title', {
    staticClass: "ml-8"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('AwaitingInvoicing', {
        number: _vm.ordersAwaitingInvoice.length
      }))
    }
  })]), _c('v-card-text', [_c('transition-group', {
    attrs: {
      "name": "list-complete",
      "tag": "div"
    }
  }, [_vm._l(_vm.ordersAwaitingInvoice, function (order, index) {
    return [index < 2 || _vm.displayMoreOrdersAwaitingInvoice ? _c('v-row', {
      key: order._id + index,
      staticClass: "action mb-2 list-complete-item",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "\n                  rounded-l-xl\n                  outlined\n                  align-self-stretch\n                  d-flex\n                  align-center\n                  pl-2 pl-md-6\n                ",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('div', {
      staticClass: "pa-1 d-flex flex-wrap text-subtitle-1 align-center"
    }, [_c('span', {
      staticClass: "d-inline-block text-truncate",
      staticStyle: {
        "max-width": "250px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('orderNumber', {
      docNumber: order.invoiceNumber
    })) + " ")])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "\n                    white--text\n                    align-self-stretch\n                    rounded-l-0 rounded-r-xl\n                  ",
      attrs: {
        "color": "orange",
        "depressed": "",
        "height": "100%",
        "large": "",
        "block": "",
        "to": {
          name: 'Order',
          params: {
            supplierUUID: "".concat(_vm.$route.params.supplierUUID),
            orderUUID: "".concat(order._id)
          }
        },
        "ripple": false
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-plus ")]), _c('v-icon', [_vm._v("fas fa-file-invoice")])], 1)], 1)], 1) : _vm._e()];
  })], 2), _vm.ordersAwaitingInvoice.length > 2 ? _c('v-row', {
    staticStyle: {
      "position": "absolute",
      "right": "50%",
      "left": "50%"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "py-4 mt-3 white elevation-0",
    attrs: {
      "right": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.displayMoreOrdersAwaitingInvoice = !_vm.displayMoreOrdersAwaitingInvoice;
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "duration": _vm.displayMoreOrdersAwaitingInvoice ? {
        enter: 200,
        leave: 200
      } : {
        enter: 1000,
        leave: 500
      },
      "tag": "div",
      "mode": "out-in"
    }
  }, [!_vm.displayMoreOrdersAwaitingInvoice ? _c('span', {
    key: "arrowIconDown",
    staticClass: "\n                    text-none text-body-2\n                    d-flex\n                    align-center\n                    orange--text\n                  "
  }, [_vm._v(" " + _vm._s(_vm.$t("Plus", {
    number: _vm.ordersAwaitingInvoice.length - 2
  })) + " ")]) : _c('span', {
    key: "arrowIconUp"
  }, [_c('v-icon', {
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("fas fa-chevron-up")])], 1)])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1) : _c('v-card-text', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "height": "80%"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-1 grey--text mb-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('NoActionsToDo')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }