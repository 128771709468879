<template lang="html" src="./pageDashboard.template.vue"></template>

<style lang="scss" src="./pageDashboard.scss"></style>

<script>
import ActionsToDo from '../../components/actionsToDo/ActionsToDo';
import FollowUpOfOrdersInProgress from '../../components/followUpOfOrdersInProgress/FollowUpOfOrdersInProgress';
import StatisticsCard from '../../components/statisticsCard/StatisticsCard';
import UploadOrgParts from '../../components/uploadOrgParts/UploadOrgParts';
import { ApiErrorParser, SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageDashboardi.i18n');

export default {
  name: 'PageDashboard',
  components: {
    ActionsToDo,
    FollowUpOfOrdersInProgress,
    SpinnerBeelse,
    StatisticsCard,
    UploadOrgParts,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      awaitingInvoicingOrders: null,
      wizardItems: [],
      warehouseProgressQuota: null,
      homologationList: [],
      orders: null,
      ordersAwaitingPayment: [],
      quotes: null,
      totalCountParts: 0,
      durationValues: [],
      averageDeliveryTimeReady: false,
      averageDeliveryTime: 0,
      loadingActionsToDoAndOrdersStatus: null,
      manufacturingCountParts: 0,
      ordersAwaitingInvoice: null,
      ordersScheduled: [],
      ordersDelivered: 0,
      ordersInDelivery: 0,
      partsAwaitingPlacement: null,
      printers: null,
      quotesReady: [],
      currentOrders: [],
      supplierHomologationsTodoList: [],
      showMoreAwaitingOrders: false,
      showMoreQuotesReady: false,
      showMoreQuoteQuantity: 2,
      showMoreOrderQuantity: 2,
      showMoreHomologationQuantity: 2,
      supplierName: null,
      loadingCard: false,
    };
  },
  created() {
    this.getSupplier();
    this.getActionsToDoAndOrdersStatus();
    // this.getPrintersLines();
  },
  methods: {
    dayDiff(date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      const diff = {};
      let tmp = date2 - date1;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;

      return diff.day;
    },
    getActionsToDoAndOrdersStatus() {
      this.loadingActionsToDoAndOrdersStatus = true;
      this.getSupplierOrders();
    },
    getAllHomologations() {
      /**
       * GET ALL PARTS
       */
      this.$apiInstance
        .getSupplierParts(this.$route.params.supplierUUID)
        .then(
          (data) => {
            data.forEach((part) => {
              if (part.homologation.status === 'SUPPLIER_QUOTATION') {
                part.last_modified = new Date(part.last_modified).getTime();
                this.homologationList.push(part);
              }
            });
            this.verifyActionsToDoTotal();
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID).then(
        (data) => {
          this.supplierName = data.name;
        },
        (error) => {
          /**
           * ERROR GET SUPPLIER PARTS
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * GET SUPPLIER ORDERS
     */
    getSupplierOrders() {
      this.$apiInstance
        .getSupplierOrders(this.$route.params.supplierUUID)
        .then(
          (orders) => {
            this.actionsToDo = 0;
            this.awaitingInvoicingOrders = null;
            this.homologationList = [];
            this.orders = null;
            this.ordersAwaitingPayment = [];
            this.totalCountParts = 0;
            this.manufacturingCountParts = 0;
            this.ordersAwaitingInvoice = null;
            this.ordersScheduled = [];
            this.ordersDelivered = 0;
            this.ordersInDelivery = 0;
            this.partsAwaitingPlacement = null;
            this.printers = null;
            this.currentOrders = [];
            this.supplierHomologationsTodoList = [];
            this.orders = orders.sort(function(a, b) {
              return b.created - a.created;
            });
            this.currentOrders = [];
            this.ordersAwaitingInvoice = [];
            for (let i = 0; i < this.orders.length; i++) {
              if (
                (this.orders[i].quote.dispatch.status === 'MANUFACTURED' ||
                  this.orders[i].quote.dispatch.status === 'DELIVERY' ||
                  this.orders[i].quote.dispatch.status === 'DELIVERED') &&
                !this.orders[i].quote.dispatch.invoiceFile
              ) {
                this.ordersAwaitingInvoice.push(this.orders[i]);
              }

              if (this.orders[i].quote.dispatch.status === 'SCHEDULED') {
                this.orders[i].step = 0;
                this.ordersScheduled.push(this.orders[i]);
              }
              if (this.orders[i].quote.dispatch.status === 'MANUFACTURING') {
                this.orders[i].step = 1;
                this.manufacturingCountParts += this.orders[i].quote.dispatch.items.reduce((previousValue, i ) => {return previousValue + i.quantity;}, 0);;
              }
              if (this.orders[i].quote.dispatch.status === 'MANUFACTURED') {
                this.orders[i].step = 2;
              }
              if (this.orders[i].quote.dispatch.status === 'DELIVERY') {
                this.orders[i].step = 3;
                this.ordersInDelivery += 1;
              }
              if (this.orders[i].quote.dispatch.status === 'DELIVERED') {
                this.averageDeliveryTimeReady = true;
                this.orders[i].step = 4;
                const orderDuration = this.dayDiff(
                  this.orders[i].created,
                  this.orders[i].last_modified
                );
                this.ordersDelivered += 1;
                this.averageDeliveryTime += parseFloat(orderDuration);
                this.totalCountParts += this.orders[i].quote.dispatch.items.reduce((previousValue, i ) => {return previousValue + i.quantity;}, 0);
              } else {
                this.currentOrders.push(this.orders[i]);
              }
            }
            this.getAllHomologations();
          },
          (error) => {
            /**
             * ERROR GET SUPPLIER PARTS
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingCard = true;
        });
    },
    resetDataAndReload() {
      this.loadingActionsToDoAndOrdersStatus = true;
      this.getSupplier();
      this.getActionsToDoAndOrdersStatus();
    },
    unlockSpinner() {
      setTimeout(() => {
        this.loadingActionsToDoAndOrdersStatus = false;
      }, 1500);
    },
    verifyActionsToDoTotal() {
      this.actionsToDo = 0;
      if (this.homologationList) {
        this.actionsToDo += this.homologationList.length;
      } else {
        this.homologationList = [];
      }
      if (this.ordersScheduled) {
        this.actionsToDo += this.ordersScheduled.length;
      } else {
        this.ordersScheduled = [];
      }
      if (this.ordersAwaitingInvoice) {
        this.actionsToDo += this.ordersAwaitingInvoice.length;
      } else {
        this.ordersAwaitingInvoice = [];
      }
      this.unlockSpinner();
    },
  },
};
</script>
