var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-statisticsCard d-flex flex-wrap justify-center col-12 pt-0"
  }, [_vm.spinnerIsRunning ? _c('v-row', {
    staticClass: "col-12",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('SpinnerBeelse', {
    attrs: {
      "color": "orange",
      "multiple": "",
      "logo": "SimpleB",
      "width": "150",
      "display-logo": false
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "chart1 col-8",
    class: this.totalPartsArchivedByPrintedLine && this.totalPartsArchivedByPrintedLine.length === 0 ? 'chart1Empty' : '',
    style: _vm.spinnerIsRunning ? 'height: 0' : 'height:210px'
  }, [_c('canvas', {
    style: _vm.spinnerIsRunning ? 'height: 0' : 'height:210px',
    attrs: {
      "id": "myChart",
      "width": "300",
      "height": "210"
    }
  })]), !_vm.spinnerIsRunning && _vm.totalPartsArchivedByPrintedLine && _vm.totalPartsArchivedByPrintedLine.length > 0 ? _c('div', {
    staticClass: "col-auto text-center mt-8"
  }, [_c('div', {
    staticClass: "d-inline pa-5 orange elevation-3 rounded totalPartsPrinted text-no-wrap"
  }, [_vm._l(_vm.formatTotalPrintedParts(), function (number, index) {
    return _c('span', {
      key: index,
      staticClass: "pa-2 white mx-1 elevation-2 flip-in-ver-right rounded",
      class: number === '0' ? 'd-none d-sm-inline' : ''
    }, [_vm._v(" " + _vm._s(number) + " ")]);
  }), _c('span', {
    staticClass: "ml-2 white--text font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('PartsPrinted')))])], 2)]) : _vm._e(), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart2 card col-12"
  }, [_c('canvas', {
    attrs: {
      "id": "myLineChart",
      "width": "300",
      "height": "178"
    }
  })]);
}]

export { render, staticRenderFns }