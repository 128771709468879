<template lang="html" src="./followUpOfOrdersInProgress.template.vue"></template>

<style lang="scss" src="./followUpOfOrdersInProgress.scss"></style>

<script>
const i18nData = require('./followUpOfOrdersInProgress.i18n');
export default {
  name: 'FollowUpOfOrdersInProgress',
  components: {},
  props: {
    orders: {
      type: Array,
      required: true,
    },
    maximum: {
      type: Number,
      default: null,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
};
</script>
