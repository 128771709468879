var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-followUpOfOrdersInProgress"
  }, [_c('v-card', {
    staticClass: "cardOrdersTracking"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-clipboard-list ")]), _vm._v(" " + _vm._s(_vm.$t('StatusOfCurrentOrders')) + " (" + _vm._s(_vm.orders.length) + ") "), _c('v-spacer'), _c('div', {
    staticClass: "legend"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "blue-grey"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Manufacturing')) + " ")], 1), _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "#169f85"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Manufactured')) + " ")], 1), _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "orange"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Delivery')) + " ")], 1)], 1)], 1), _vm.orders.length > 0 ? _c('v-card-text', [_vm._l(_vm.orders, function (order, index) {
    return [order.step < 4 && (_vm.maximum ? index < _vm.maximum : '') ? _c('v-card', {
      key: order._id,
      staticClass: "mb-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "center",
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "text-none text-nowrap noWrapInWord rounded-r-0",
      attrs: {
        "text": "",
        "to": {
          name: 'Order',
          params: {
            supplierUUID: _vm.$route.params.supplierUUID,
            orderUUID: order._id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('orderNumber', {
      docNumber: order.invoiceNumber
    })) + " ")])], 1), _c('v-col', {
      staticClass: "px-2",
      staticStyle: {
        "position": "relative"
      }
    }, [_c('v-progress-linear', {
      attrs: {
        "background-color": "grey lighten-2",
        "height": "10",
        "rounded": "",
        "color": order.step === 1 ? 'blue-grey' : order.step === 2 ? '#169f85' : 'orange',
        "value": order.step * 100 / 4
      }
    }), order.step && order.step < 4 ? _c('v-tooltip', {
      attrs: {
        "left": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "iconFollowUpOfOrdersInProgress white",
            style: "margin-left: ".concat(order.step / 4 * 100, "%;"),
            attrs: {
              "fab": "",
              "to": {
                name: 'Order',
                params: {
                  supplierUUID: _vm.$route.params.supplierUUID,
                  orderUUID: order._id
                }
              }
            }
          }, 'v-btn', attrs, false), on), [order.step === 1 ? _c('v-img', {
            staticClass: "iconFollowUpOfOrdersInProgressImg",
            attrs: {
              "src": require("../../../public/img/icons/manufacturingIcon.svg"),
              "alt": "manufacturing icon 3D print",
              "max-width": "28px",
              "max-height": "28px"
            }
          }) : _vm._e(), order.step === 2 ? _c('v-img', {
            staticClass: "iconFollowUpOfOrdersInProgressImg",
            attrs: {
              "src": require("../../../public/img/icons/cubeCheckedOrangeIcon.svg"),
              "alt": "icon 3D print",
              "max-width": "28px",
              "max-height": "28px",
              "height": "auto"
            }
          }) : _vm._e(), order.step === 3 ? _c('v-img', {
            staticClass: "iconFollowUpOfOrdersInProgressImg",
            attrs: {
              "src": require("../../assets/iconDelivery.png"),
              "alt": "icon 3D print",
              "max-width": "28px",
              "max-height": "28px",
              "height": "auto"
            }
          }) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(order.status.charAt(0).toUpperCase() + order.status.slice(1).toLowerCase())) + " ")])]) : _vm._e()], 1)], 1)], 1) : _vm._e()];
  }), _vm.maximum && _vm.orders.length > _vm.maximum ? _c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "py-4 white text-none caption elevation-0",
    attrs: {
      "ripple": false,
      "to": {
        name: 'Orders'
      },
      "small": ""
    }
  }, [_c('span', {
    staticClass: "d-flex text-none text-body-1 align-center orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Plus", {
    number: _vm.orders.length - _vm.maximum
  })) + " ")]), _c('v-icon', {
    staticClass: "ml-3",
    attrs: {
      "color": "orange",
      "small": ""
    }
  }, [_vm._v(" fas fa-chevron-right ")])], 1)], 1)], 1) : _vm._e()], 2) : _c('v-card-text', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "height": "80%"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-1 grey--text mb-12"
  }, [_vm._v(_vm._s(_vm.$t('NoOrders')))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }