<template lang="html" src="./actionsToDo.template.vue"></template>

<style lang="scss" src="./actionsToDo.scss"></style>

<script>
const i18nData = require('./actionsToDo.i18n');
export default {
  name: 'ActionsToDo',
  components: {},
  props: {
    ordersScheduled: {
      type: Array,
      required: true,
    },
    ordersAwaitingInvoice: {
      type: Array,
      required: true,
    },
    homologations: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      displayMoreOrdersAwaitingInvoice: false,
      displayMoreHomologationList: false,
      displayMoreOrdersScheduled: false,
    };
  },
  computed: {
    actionsToDo() {
      return this.ordersScheduled.length + this.homologations.length + this.ordersAwaitingInvoice.length;
    }
  },
  created() {
  },
  methods: {
    orderItemsQuantity(order) {
      return order.quote.dispatch.items.reduce((previousValue, i) => {return previousValue + i.quantity;}, 0);
    }
  },
};
</script>
